/* eslint-disable no-undef */
import 'url-search-params-polyfill';
import moment from 'moment';
import ReactGA4 from 'react-ga4';
import { strings } from './localizedStrings';
import { refundTypeCodes } from '../components/return/defaultValues';

/**
 * Generate html template based on type
 * @param  {number} monthNumber monthNumber hold the index value for month.
 * @return {string} Return 3 digit string value based on index from months array.
 */
export const getMonthName = monthNumber => {
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  if (monthNumber === 0) {
    monthNumber = 12;
  }
  return months[monthNumber - 1];
};

export const calculatePercentage = num => {
  switch (num) {
    case 2:
      return [0, 100];
    case 3:
      return [0, 50, 100];
    case 4:
      return [0, 33.33, 66.67, 100];
    case 5:
      return [0, 25, 50, 75, 100];
    case 6:
      return [0, 20, 40, 60, 80, 100];
    case 7:
      return [0, 16.5, 33, 49.5, 67, 83, 100];
    default:
      return [];
  }
};

export const ellipsify = (str, len) => {
  return str.length > len ? str.slice(0, len) + '...' : str;
};

/**
 * It's used for nps
 * @param  {number} expression rating value.
 * @return {object} Return object based on expression
 */
export const generateRatingMeta = (expression, isOneToFive, lang) => {
  const metaObj = {
    terrible: {
      type: 'Terrible',
      cssClass: 'terrible',
      placeholder: strings[lang]?.ratingMeta(0),
      requiredComment: false,
      tag: []
    },
    bad: {
      type: 'Bad',
      cssClass: 'bad',
      placeholder: strings[lang]?.ratingMeta(0),
      requiredComment: false,
      tag: []
    },
    ok: {
      type: 'Ok',
      cssClass: 'ok',
      placeholder: strings[lang]?.ratingMeta(6),
      requiredComment: false,
      tag: []
    },
    good: {
      type: 'Good',
      cssClass: 'good',
      placeholder: strings[lang]?.ratingMeta(),
      requiredComment: false,
      tag: []
    },
    excellent: {
      type: 'Excellent',
      cssClass: 'excellent',
      placeholder: strings[lang]?.ratingMeta(),
      requiredComment: false,
      tag: []
    }
  };

  if (isOneToFive) {
    switch (expression) {
      case 1:
      case 2:
        return metaObj.bad;
      case 3:
        return metaObj.ok;
      case 4:
        return metaObj.good;
      case 5:
        return metaObj.excellent;
      default:
        return {};
    }
  }

  switch (expression) {
    case 0:
    case 1:
    case 2:
      return metaObj.terrible;
    case 3:
    case 4:
      return metaObj.bad;
    case 5:
    case 6:
      return metaObj.ok;
    case 7:
    case 8:
      return metaObj.good;
    case 9:
    case 10:
      return metaObj.excellent;
    default:
      return {};
  }
};

export const hexToRgb = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

export const clickEventDescription = number => {
  switch (number) {
    case 0:
      return 'Did you face any other issue?';
    case 1:
      return 'No attempt was made to the delivery address';
    case 2:
      return 'I was not available when delivery attempt was made';
    case 3:
      return 'Others';
    case 4:
      return 'Delivery Agent did not call me';
    case 6:
      return 'Do not want the product anymore';
    default:
  }
};

export const nprClickEventDescription = number => {
  switch (number) {
    case 0:
      return 'Did you face any other issue?';
    case 5:
      return 'No attempt was made to the pickup address';
    case 6:
      return 'I was not available when pickup attempt was made';
    case 3:
      return 'Others';
    case 1:
      return 'Pickup Agent did not call me';
    case 2:
      return 'Do not want to return the product any more';
    default:
  }
};

export const paths = {
  forward: [
    '/',
    '/page',
    '/page/',
    '/tracking',
    '/tracking/',
    '/reverse',
    '/reverse/issue',
    '/en',
    '/en/',
    '/en/page',
    '/en/page/',
    '/en/tracking',
    '/en/tracking/',
    '/en/reverse',
    '/en/reverse/issue',
    '/ar',
    '/ar/',
    '/ar/page',
    '/ar/page/',
    '/ar/tracking',
    '/ar/tracking/',
    '/ar/reverse',
    '/ar/reverse/issue',
    '/vi',
    '/vi/',
    '/vi/page',
    '/vi/page/',
    '/vi/tracking',
    '/vi/tracking/',
    '/vi/reverse',
    '/vi/reverse/issue',
    '/hi',
    '/hi/',
    '/hi/page',
    '/hi/page/',
    '/hi/tracking',
    '/hi/tracking/',
    '/hi/reverse',
    '/hi/reverse/issue',
    '/mr',
    '/mr/',
    '/mr/page',
    '/mr/page/',
    '/mr/tracking',
    '/mr/tracking/',
    '/mr/reverse',
    '/mr/reverse/issue',
    '/bn',
    '/bn/',
    '/bn/page',
    '/bn/page/',
    '/bn/tracking',
    '/bn/tracking/',
    '/bn/reverse',
    '/bn/reverse/issue',
    '/gu',
    '/gu/',
    '/gu/page',
    '/gu/page/',
    '/gu/tracking',
    '/gu/tracking/',
    '/gu/reverse',
    '/gu/reverse/issue',
    '/te',
    '/te/',
    '/te/page',
    '/te/page/',
    '/te/tracking',
    '/te/tracking/',
    '/te/reverse',
    '/te/reverse/issue',
    '/ta',
    '/ta/',
    '/ta/page',
    '/ta/page/',
    '/ta/tracking',
    '/ta/tracking/',
    '/ta/reverse',
    '/ta/reverse/issue',
    '/id',
    '/id/',
    '/id/page',
    '/id/page/',
    '/id/tracking',
    '/id/tracking/',
    '/id/reverse',
    '/id/reverse/issue',
    '/kn',
    '/kn/',
    '/kn/page',
    '/kn/page/',
    '/kn/tracking',
    '/kn/tracking/',
    '/kn/reverse',
    '/kn/reverse/issue',
    '/fr',
    '/fr/',
    '/fr/page',
    '/fr/page/',
    '/fr/tracking',
    '/fr/tracking/',
    '/fr/reverse',
    '/fr/reverse/issue'
  ],
  return: [
    '/returns',
    '/return-exchange',
    '/returns/status',
    '/en/returns',
    '/en/returns/status',
    '/en/returns/',
    '/en/return-exchange',
    '/en/return-exchange/',
    '/en/returns/status/',
    '/ar/returns',
    '/ar/returns/status',
    '/ar/returns/',
    '/ar/return-exchange',
    '/ar/return-exchange/',
    '/ar/returns/status/',
    '/vi/returns',
    '/vi/returns/status',
    '/vi/returns/',
    '/vi/return-exchange/',
    '/vi/return-exchange',
    '/vi/returns/status/',
    '/hi/returns',
    '/hi/returns/status',
    '/hi/returns/',
    '/hi/return-exchang/',
    '/hi/return-exchange/',
    '/hi/returns/status/',
    '/mr/returns',
    '/mr/returns/status',
    '/mr/returns/',
    '/mr/return-exchange',
    '/mr/return-exchange/',
    '/mr/returns/status/',
    '/bn/returns',
    '/bn/returns/status',
    '/bn/returns/',
    '/bn/return-exchange',
    '/bn/return-exchange/',
    '/bn/returns/status/',
    '/gu/returns',
    '/gu/returns/status',
    '/gu/returns/',
    '/gu/return-exchange',
    '/gu/return-exchange/',
    '/gu/returns/status/',
    '/te/returns',
    '/te/returns/status',
    '/te/returns/',
    '/te/return-exchange',
    '/te/return-exchange/',
    '/te/returns/status/',
    '/ta/returns',
    '/ta/returns/status',
    '/ta/returns/',
    '/ta/return-exchange',
    '/ta/return-exchange/',
    '/ta/returns/status/',
    '/id/returns',
    '/id/returns/status',
    '/id/returns/',
    '/id/return-exchange',
    '/id/return-exchange/',
    '/id/returns/status/',
    '/kn/returns',
    '/kn/returns/status',
    '/kn/returns/',
    '/kn/return-exchange',
    '/kn/return-exchange/',
    '/kn/returns/status/',
    '/fr/returns',
    '/fr/returns/status',
    '/fr/returns/',
    '/fr/return-exchange',
    '/fr/return-exchange/',
    '/fr/returns/status/'
  ]
};

export const routeParser = location => {
  const { search, hash } = location;
  const searchParams = new URLSearchParams(search + hash); //queryString.parse(location.search);
  const query = {
    key: searchParams.get('security_key'),
    waybill: searchParams.get('waybill'),
    orderId: searchParams.get('order_id'),
    mobile: searchParams.get('mobile'),
    email: searchParams.get('email'),
    cpId: searchParams.get('cp_id'),
    awbs: searchParams.get('awbs'),
    referenceNumber: searchParams.get('reference_number')
  };
  if (query.orderId) {
    query.orderId = encodeURIComponent(query.orderId);
  }
  const routeInfo = { isReturnOrderUrl: false, isTrackingUrl: false };
  if (
    paths.return.indexOf(location.pathname) > -1 &&
    ((query.waybill && query.key) ||
      (query.orderId && query.mobile) ||
      (query.orderId && query.email) ||
      (query.orderId && query.key) ||
      (query.orderId && query.referenceNumber) ||
      query.awbs)
  ) {
    routeInfo.isReturnOrderUrl = true;
  }
  if (
    paths.forward.indexOf(location.pathname) > -1 &&
    ((query.cpId && (query.waybill || query.orderId)) ||
      query.waybill ||
      query.orderId)
  ) {
    routeInfo.isTrackingUrl = true;
  }
  return routeInfo;
};

export const pincodePattern = new RegExp(/^\d{6}$/);
export const phonePattern = new RegExp(/^\d{10}$/);
export const datePattern = new RegExp(/[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/);
export const datePattern2 = new RegExp(/[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/);
export const commonTextRegex = { en: '^[\\w\\s!&()\\-_\'":?/.,@;+=*%]*$' };

export const shorterTimeline = order => {
  let reached = 0;
  if (order.reached > 0 && order.reached < order.timeline.length) {
    reached = 1;
  } else if (order.reached === order.timeline.length) {
    reached = 2;
  }
  let timeline = [order.timeline[0], order.timeline[order.timeline.length - 1]];
  if (reached === 1) {
    timeline.splice(1, 0, order.timeline[order.reached]);
  }
  order.reached = reached;
  order.timeline = timeline;
  if (order.timeline.length === 3) {
    order.percentage = [0, 50, 100];
  } else {
    order.percentage = [0, 100];
  }
  return order;
};

export const mappingDateByStatuses = statuses => {
  let dateSeries = [];
  let uniquedates = [];

  for (let index = 0; index < statuses.length; index++) {
    const date = statuses[index].timestamp.substr(0, 10);
    if (uniquedates.indexOf(date) < 0) {
      uniquedates.push(date);
    }
  }

  uniquedates = uniquedates.sort((b, a) => new Date(a) - new Date(b));
  for (let i = 0; i < uniquedates.length; i++) {
    for (let j = 0; j < statuses.length; j++) {
      const date = statuses[j].timestamp.substr(0, 10);
      if (uniquedates[i] === date) {
        if (dateSeries[i] && dateSeries[i].date) {
          dateSeries[i].list.push({
            location: statuses[j].location,
            timestamp: statuses[j].timestamp,
            remark: statuses[j].remark
          });
        } else {
          dateSeries[i] = {
            date,
            list: [
              {
                location: statuses[j].location,
                timestamp: statuses[j].timestamp,
                remark: statuses[j].remark
              }
            ]
          };
        }
      }
    }
  }
  return dateSeries;
};

export const datetimeFormatter = (
  datetime,
  isRelativeFormat,
  isTime,
  format
) => {
  if (format) {
    return moment(datetime).format(format);
  }
  const time = moment(datetime).format('LT');
  const currentDate = moment().format('YYYY-MM-DD');
  const days = moment(moment(datetime).format('YYYY-MM-DD')).diff(
    currentDate,
    'day'
  );
  let dateString = null;
  if (days === 0 && isRelativeFormat) {
    dateString = 'Today';
  } else if (days === 1 && isRelativeFormat) {
    dateString = 'Tomorrow';
  } else if (days === -1 && isRelativeFormat) {
    dateString = 'Yesterday';
  } else {
    dateString = moment(datetime).format('dddd, Do MMM');
  }
  return `${dateString}${isTime ? ', ' + time : ''}`;
};

export const enableFormSubmit = form => {
  let valid = true;
  if (form.currentIssueId === 1) {
    if (form?.address?.length < 10 && form?.capture?.address) {
      valid = false;
    }
    if (
      !pincodePattern.test(form.drop_pincode) &&
      form?.capture?.drop_pincode
    ) {
      valid = false;
    }
    if (form?.landmark?.length < 8 && form?.capture?.landmark) {
      valid = false;
    }
    if (!form.preferred_date) {
      valid = false;
    }
  } else if (form.currentIssueId === 2 || form.currentIssueId === 3) {
    if (!form.preferred_date) {
      valid = false;
    }
  } else if (form.currentIssueId === 4) {
    if (
      form.isAltNumber &&
      !phonePattern.test(form.phone_number) &&
      form?.capture?.address
    ) {
      valid = false;
    }
    if (form.preferred_date && form.isAltNumber === null) {
      valid = false;
    }
    if (!form.preferred_date) {
      valid = false;
    }
  } else if (form.currentIssueId === 6) {
    if (form.is_rejected === null) {
      valid = false;
    } else if (!form.is_rejected && !form.preferred_date) {
      valid = false;
    }
  }
  return valid;
};

export const ndrButtonText = key => {
  switch (key) {
    case 0:
      return 'Next';
    case 1:
    case 4:
      return 'Request Delivery';
    case 2:
      return 'Select Delivery Preference';
    default:
      return 'Submit';
  }
};

export const validateCodForm = form => {
  if (
    form.name &&
    form.accountNumber &&
    form.ifsccode &&
    form.ifsccode.length === 11 &&
    form.bankName &&
    form.branchName
  ) {
    return false;
  } else {
    return true;
  }
};

export const parseQueryString = queryString => {
  const qs = queryString.replace('?', '');
  const items = qs.split('&');
  return items.reduce((data, item) => {
    const [key, value] = item.split('=');
    if (data[key] !== undefined) {
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]];
      }
      data[key].push(value);
    } else {
      data[key] = value;
    }
    return data;
  }, {});
};

export const filterObject = function(ar, key, value) {
  let obj = {};
  obj = ar.filter(function(obj) {
    return obj[key] === value;
  })[0];
  return obj;
};

export const validateRefund = (orderType, captureBankDetail, storeCredit) => {
  let confirmedRefundPayment = '';
  if (orderType === 'COD' && captureBankDetail && storeCredit) {
    confirmedRefundPayment = '';
  } else if (orderType === 'COD' && !captureBankDetail && !storeCredit) {
    confirmedRefundPayment = refundTypeCodes.source;
  } else if (orderType === 'COD' && !captureBankDetail && storeCredit) {
    confirmedRefundPayment = refundTypeCodes.source;
  } else if (orderType === 'COD' && captureBankDetail && !storeCredit) {
    confirmedRefundPayment = '';
  } else if (orderType === 'PREPAID' && captureBankDetail && storeCredit) {
    confirmedRefundPayment = '';
  } else if (orderType === 'PREPAID' && !captureBankDetail && !storeCredit) {
    confirmedRefundPayment = refundTypeCodes.source;
  } else if (orderType === 'PREPAID' && !captureBankDetail && storeCredit) {
    confirmedRefundPayment = '';
  } else if (orderType === 'PREPAID' && captureBankDetail && !storeCredit) {
    confirmedRefundPayment = refundTypeCodes.source;
  }
  return confirmedRefundPayment;
};

/**
 * Removes a param from the search part of a URL.
 * @param {string} search Search part of a URL, e.g. location.search.
 * @param {string} name Param name.
 * @return {string} Modified search.
 */
export const removeSearchParam = (search, name) => {
  if (search[0] === '?') {
    search = search.substring(1);
  }
  var parts = search.split('&');
  var res = [];
  for (var i = 0; i < parts.length; i++) {
    var pair = parts[i].split('=');
    if (pair[0] === name) {
      continue;
    }
    res.push(parts[i]);
  }
  search = res.join('&');
  if (search.length > 0) {
    search = '?' + search;
  }
  return search;
};

export function openUrl(url, target) {
  const aTag = document.createElement('a');
  aTag.rel = 'noopener';
  aTag.target = target;
  aTag.href = url;
  aTag.click();
}

export const ga4ClickEvent = ({ props, category, label, action }) => {
  const { page, user, tracking } = props;
  // Added another event
  ReactGA4.event('custom_click', {
    category,
    label: label,
    activity: action,
    custom_category: category,
    custom_label: label,
    custom_action: action,
    enterprise: user.enterprise?.toString(),
    uber_status: tracking.latestStatus.code?.toString(),
    waybill: tracking.waybill?.toString(),
    cp_name: tracking.courier.actName,
    tracking_source: page.source || '',
    cp_id: tracking.courier.id?.toString(),
    enterprise_id: tracking.theme.enterprise_user_id?.toString()
  });
  ReactGA4.event('user_click', {
    category,
    label: label,
    activity: action,
    enterprise: user.enterprise?.toString(),
    uber_status: tracking.latestStatus.code?.toString(),
    waybill: tracking.waybill?.toString(),
    cp_name: tracking.courier.actName,
    tracking_source: page.source || '',
    cp_id: tracking.courier.id?.toString(),
    enterprise_id: tracking.theme.enterprise_user_id?.toString()
  });
};


export const zipyInit = (orderInfo, user) => {
  if (window?.zipy && orderInfo && user) {
    window.zipy.identify(`${user.subdomain} ${orderInfo?.reference_number}`, {
      customerName: user.subdomain,
      orderId: orderInfo?.reference_number,
      awb: orderInfo?.awb,
      subdomain: user.subdomain,
      orderType: orderInfo?.order_type
    });
  }
};