/* eslint-disable no-useless-escape */
import { getMonthName, calculatePercentage } from './helper';
import { strings } from './localizedStrings';

export const order = {
  orderPlaced: [1, 2, 3, 28, 25],
  readyToShip: [1001],
  orderPlacedButNotShipped: [1, 2, 3, 28, 25, 1001],
  dispatched: [4],
  inTransit: [5, 18, 19, 20, 1004, 1005, 1006],
  shipped: [4, 5, 18, 19, 20, 1001],
  outForDelivery: [6],
  delivered: [8, 32],
  cancelled: [10],
  good: [1, 2, 3, 28, 25, 1001, 4, 5, 18, 19, 20, 6, 8, 32],
  eddStatuses: [1, 2, 3, 28, 25, 1001, 4, 5, 18, 19, 20],
  bad: [16, 17, 23],
  lost: [16],
  damaged: [17],
  expired: [23],
  returned: [11, 12, 13, 14, 15, 21, 26, 27],
  failedDelivery: [7, 9],
  blockedContactUpdate: [6, 8, 16, 17, 11, 12, 13, 14, 15, 21, 26, 27, 7, 9],
  state: [],
  label: [],
  isRTO: false,
  isFailed: false,
  percentage: [0, 20, 40, 60, 80, 100],
  timelineColor: '#ff6500',
  reached: -1,
  failedDeliveryOverride: [8, 32, 10, 16, 17, 11, 12, 13, 14, 15, 21, 26, 27]
};

export const returnOrder = {
  placed: [],
  confirmed: [28, 1, 2, 3, 25],
  cancelled: [10, 23],
  pickedUp: [
    0,
    4,
    5,
    6,
    7,
    9,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    24,
    26,
    27
  ],
  delivered: [8]
};

export const reverseOrder = {
  pickupRequested: [1, 2, 28],
  outForPickup: [25],
  pickupFailed: [3],
  pickedup: [4, 5, 6, 7, 9, 18, 19, 20, 30, 31, 32],
  pickupCancelled: [10],
  returned: [8]
};

export const trackingInfo = {
  courier: { name: '', logo: '' },
  orderId: '',
  trackingId: '',
  waybill: '',
  scans: [],
  order: {
    timeline: []
  },
  latestStatus: { code: null },
  config: { formStatusCodes: [], user: '' },
  theme: { isFetching: true },
  banners: [],
  customer: {
    name: '',
    phone: '',
    address: '',
    pincode: ''
  },
  product: { title: '', url: '' },
  predict: { color: null, edd: null, message: null },
  rating: { star: 0, comment: null },
  isReverseFetched: false,
  predictEDD: {},
  reverseRating: { star: 0, comment: null }
};

export const rating = { star: null, comment: null, ratedBefore: false };

export const reverseRating = { star: null, comment: null, ratedBefore: false };

export const customerFeed = {
  drop_pincode: null,
  comment: null,
  phone_number: null,
  preferred_date: null,
  preferred_time: null,
  landmark: null,
  address: null,
  issue_id: null,
  capturePincode: false,
  timestamp: null
};
export const opsFeed = {
  bucket_name: null,
  issue_id: null,
  issue_name: null,
  raw_issue_desc: null
};

export const npr = {
  ops: {
    bucket_name: null,
    issue_id: null,
    issue_name: null,
    raw_issue_desc: null
  },
  customer: {
    drop_pincode: null,
    comment: null,
    phone_number: null,
    preferred_date: null,
    preferred_time: null,
    landmark: null,
    address: null,
    issue_id: null
  }
};

export const googleSearch = { list: [], isFetching: true, error: false };

export const baseTheme = {
  font_family: 'Open Sans',
  font_family_url:
    "url('https://s3-ap-southeast-1.amazonaws.com/pyck-res-bucket/clickpost_assets/OpenSansRegular.woff2')",
  font_size: '16px',
  font_weight: '400',
  primary_color: '#4a4a4a',
  secondary_color: '#878787',
  background_color: '#f5f5f5',
  nav_color: '#000',
  nav_color_hover: '#fe5d41',
  max_width: '1920px',
  gutter: '3.5%',
  border_color: '#f5a623',
  header_color: '#ee51a2',
  header_background_color: '#fff',
  meta_data: {
    email: '',
    time: {
      from: '9AM',
      to: '6PM'
    },
    day: {
      from: 'Mon',
      to: 'Sat'
    },
    contact: '',
    social: {
      fb: '',
      tw: '',
      ig: ''
    },
    app: {
      ios: '',
      android: ''
    },
    return_policy_url: '',
    term_condition_url: '',
    refund_policy_url: '',
    return_background_image: '',
    title: 'Clickpost',
    hashtag: '',
    twitterHandle: '',
    website: 'https://www.clickpost.in'
  }
};

export const reverseInfo = {
  courier: '',
  orderId: '',
  waybill: '',
  latestStatus: {},
  timeline: { color: null, percentage: [], reached: 0, states: [] }
};

export const trackingInfoMapping = (waybill, obj, lang, metaData) => {
  const { latest_status, scans, valid, additional } = obj[obj.waybill];
  const { config, customer_info } = obj;

  const product = { title: '', url: '' };
  if (additional.order_detail.length > 0) {
    product.title = additional.order_detail[0].description;
    if (additional.order_detail[0].images) {
      const images = additional.order_detail[0].images.split(',');
      product.url = images[0];
    }
  }

  let showRating = false;
  let showReverseRating = false;
  const canShowReverseNPS =
    config?.is_rvp &&
    reverseNPSStatuses.all.indexOf(scans?.[0]?.clickpost_status_code) > -1;
  if (obj.security_key_valid) {
    showRating = true;
    showReverseRating = true;
  } else if (
    scans &&
    scans.length > 0 &&
    scans[0]?.timestamp &&
    scans[0]?.clickpost_status_description &&
    (scans[0]?.clickpost_status_description === 'Delivered' ||
      canShowReverseNPS)
  ) {
    let date = scans[0].timestamp.split(' '); // converting to dd/mm/yyyy format for Safari and IE browsers
    date = date[0]?.split('-') ?? [];
    const postDeliveryDayLimit = metaData?.post_delivery_day_limit;
    if (date?.length >= 3) {
      date = date[0] + '/' + date[1] + '/' + date[2];
      const deliveredDate = new Date(date);
      const deliveryDatePlusTimeout = deliveredDate.setDate(
        postDeliveryDayLimit > 7
          ? deliveredDate.getDate() + (postDeliveryDayLimit - 1)
          : deliveredDate.getDate() + 6
      );
      showRating = new Date() <= new Date(deliveryDatePlusTimeout) ?? false;
      showReverseRating =
        canShowReverseNPS && new Date() <= new Date(deliveredDate)
          ? true
          : false;
    }
  }

  let query = {
    courier: {
      name: obj.cp_name,
      logo: obj.logo_url,
      accountCode: obj.account_code,
      id: obj.cp_id,
      actName: obj.cp_name
    },
    orderId: obj.order_id,
    trackingId: obj.tracking_id,
    waybill: obj.waybill,
    referenceNumber: obj.reference_number,
    orderType: obj.order_type,
    trackingLink: obj.tracking_link,
    latestStatus: {
      code: latest_status.clickpost_status_code,
      description: latest_status.clickpost_status_description,
      location: latest_status.location,
      remark: latest_status.remark,
      status: latest_status.status,
      timestamp: latest_status.timestamp.slice(0, 16)
    },
    scans: scans.map(item => {
      return {
        code: item.clickpost_status_code,
        description: item.clickpost_status_description,
        location: item.location,
        remark: item.remark,
        status: item.status,
        timestamp: item.timestamp,
        day: item.timestamp.substr(8, 2),
        month: getMonthName(Number(item.timestamp.substr(5, 2))),
        time: item.timestamp.substr(11, 5)
      };
    }),
    config: {
      user: config.enterprise_user,
      orderDate: config.order_date,
      orderType: config.order_type,
      shipDate: config.ship_date,
      isRms: config.rms_enabled,
      isRating: config.show_courier_rating_form,
      showRating: showRating,
      isReverseRating:
        // metaData?.reverseNPS === 'CARRIER' || metaData?.reverseNPS === 'BRAND'
        metaData?.reverseNPS !== 'NA' // enabling reverse NPS unless specifically disabled as per CDA-2460
          ? true
          : false,
      showReverseRating: showReverseRating,
      isFailedDelivery: config.is_rvp
        ? config.is_pickup_form_visible
        : config.show_failed_delivery_form,
      isPostDeliveredForm: config.show_return_form_day_check, // Show return form after product delivered
      isRto: config.show_rto_form,
      formStatusCodes: config.is_rvp
        ? config.uber_status_list_for_pickup_form
        : config.show_form_on_uber_status_list,
      website: config.enterprise_website,
      isUpdateContact: config.edit_contact_details_allowed,
      isRVP: config.is_rvp,
      invalidSecurityCheck: false
    },
    banners: config.advertisement_banner_styling_config || [],
    isNewNdr: null,
    customer: {
      name: customer_info && customer_info.drop_name,
      phone: customer_info && customer_info.drop_phone,
      address: '',
      pincode: ''
    },
    product,
    predict: { color: null, edd: null, message: null },
    valid,
    additional
  };
  if (obj.enable_security_check && !obj.security_key_valid) {
    query.config.invalidSecurityCheck = true;
  }
  if (query.config.isRVP) {
    query = reverseTimeline(reverseOrder, query, lang);
  } else {
    query = evaluteTimeline(order, query, lang);
  }
  // Manipulating orderId for 1mg
  if (
    (query.config.user === 'onemg' || query.config.user === 'onemg-test') &&
    query.orderId.includes('--')
  ) {
    const orderId = query.orderId.split('--');
    if (orderId.length > 0) {
      query.orderId = orderId[0];
    }
  }
  // Manipulating orderId for buywow & bodycupid
  if (
    (query.config.user === 'fitglow-buywow' ||
      query.config.user === 'fitglow-bodycupid') &&
    query.orderId.includes('_')
  ) {
    const orderId = query.orderId.split('_');
    if (orderId.length > 0) {
      query.orderId = orderId[0];
    }
  }
  // Hiding order Id for bewakoof
  if (query.config.user === 'bewakoof') {
    query.orderId = '';
  }
  // Seeting account name as courier partner name for self & fareye
  if (
    query.courier.name &&
    (query.courier.name.toLowerCase() === 'self' ||
      query.courier.name.toLowerCase() === 'fareye')
  ) {
    query.courier.name = query.courier.accountCode;
  }
  // Hiding order date for pharmeasy
  if (
    query.config.user === 'pharmeasy-new' ||
    query.config.user === 'pharmeasy-test'
  ) {
    query.config.orderDate = '';
  }
  // if (query.config.isRVP) {
  //   query.orderId = '';
  // }
  return query;
};

export const settings = {
  infinite: false,
  slidesToShow: 1.15,
  speed: 1000,
  autoplay: false,
  margin: 0,
  centerMode: true,
  centerPadding: '0px',
  arrows: false,
  verticalSwiping: false,
  responsive: [
    {
      breakpoint: 2160,
      settings: 'unslick'
    },
    {
      breakpoint: 1024,
      settings: 'unslick'
    },
    {
      breakpoint: 769,
      settings: {
        slidesToScroll: 1
      }
    }
  ]
};

export const sortListByDate = items => {
  const obj = {};
  for (let index = 0; index < items.length; index++) {
    if (obj.hasOwnProperty(items[index].customer_returninfo_feed_id)) {
      obj[items[index].customer_returninfo_feed_id].date =
        items[index].created_at;
      obj[items[index].customer_returninfo_feed_id].list.push(items[index]);
    } else {
      obj[items[index].customer_returninfo_feed_id] = {
        list: [items[index]],
        date: items[index].created_at
      };
    }
  }
  const result = Object.keys(obj)
    .map(key => {
      obj[key].itemInfoIds = obj[key].list.map(i => i.item_info_id).join(',');
      obj[key].returnIds = obj[key].list.map(i => i.id).join(',');
      return obj[key];
    })
    .sort((b, a) => {
      var diff = new Date(a.date) - new Date(b.date);
      return diff / (Math.abs(diff) || 1);
    });
  return result;
};

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const pincodeServiceability = {
  isFetching: true,
  isServiceable: false,
  isChecked: false,
  pickupPincode: null,
  dropPincode: null
};

export const orderInfo = {
  products: [],
  addresses: [],
  returns: [],
  action: {
    isReturnClk: false,
    activeIndex: -1,
    canGoToAddress: false,
    address: {
      select: -1,
      edit: -1,
      isEditting: false
    }
  },
  pincodeServiceability: Object.assign(pincodeServiceability),
  request: {},
  step: { current: 1, reached: 1 },
  bankDetail: {
    name: '',
    accountNumber: '',
    ifsccode: '',
    bankName: '',
    branchName: ''
  },
  upiDetails: {
    upiName: '',
    upiId: ''
  },
  orderType: '',
  isErrorInCOD: false,
  storeCredit: {
    refundAsCredit: false,
    allInCredit: null,
    totalCredit: 0,
    totalAmountPaid: 0,
    hasStoreCredit: false
  },
  exchange: {}
};

export const orderInfoMapping = result => {
  let hasMultipleShipments = false;
  let shipments = {};
  let awbs = [];
  let shipmentIDs = [];
  let returnsInfo = {};

  if (result?.return_info?.length > 0) {
    returnsInfo = result.return_info.reduce((prev, curr) => {
      prev[curr.item_info_id] = {
        returnMode: curr.return_mode
      };
      return prev;
    }, {});
  }

  if (result.item_info && result.item_info.length > 1) {
    result.item_info.forEach(item => {
      if (
        item.tracking_info__shipment_uuid &&
        shipmentIDs.indexOf(item.tracking_info__shipment_uuid) === -1
      ) {
        shipmentIDs.push(item.tracking_info__shipment_uuid);
      }
      if (
        item.tracking_info__awb &&
        awbs.indexOf(item.tracking_info__awb) === -1
      ) {
        awbs.push(item.tracking_info__awb);
      }
      item.returnMode = returnsInfo?.[item.id]?.returnMode ?? '';
    });
    if (shipmentIDs.length > 1) {
      hasMultipleShipments = true;
      shipmentIDs.forEach(shipmentID => {
        Object.assign(shipments, {
          [shipmentID]: result.item_info.filter(
            item => item.tracking_info__shipment_uuid === shipmentID
          )
        });
      });
    }
  }

  return {
    products: result.item_info.map(i => {
      const priceBreakup = i.price_breakup ?? {};
      priceBreakup.discount_percent =
        Math.round(((priceBreakup.discount ?? 0) / i.price) * 100 * 100) / 100; // multiplying and dividing by 100 to round to 2 decimal places

      return {
        ...i,
        exchangeTypesAvailable: [],
        isVariantsFetched: false,
        price_breakup: {
          ...priceBreakup
        },
        discount_price:
          priceBreakup.discount_percent > 0
            ? i.price - (i.price * priceBreakup.discount_percent) / 100
            : i.price
      };
    }),
    addresses: result.return_address.map(item => ({
      name: item.tracking_info__messagingconsumer__name,
      phone: item.tracking_info__messagingconsumer__phone,
      email: item.tracking_info__messagingconsumer__email,
      address: item.drop_address,
      pincode: item.drop_pin_code,
      city: item.drop_city,
      state: item.drop_state,
      country: item.drop_country_code,
      isSubmitted: true
    })),
    returns: sortListByDate(result.return_info),
    awb: result.awb,
    shipment_uuid: result.shipment_uuid,
    reference_number: result.reference_number,
    isCaptureImage: result.capture_image,
    orderType: result.order_type,
    hasMultipleShipments: hasMultipleShipments,
    shipments: shipments,
    awbs: awbs,
    shipmentIDs: shipmentIDs,
    currency: result.currency_code ?? null,
    refundAutomationEnabled: result.refund_automation_enabled ?? false,
    allow_future_returns: result.allow_future_returns ?? false,
    allow_future_exchanges: result.allow_future_exchanges ?? false,
    lastReturnDate: result.last_return_date ?? null,
    lastExchangeDate: result.last_exchange_date ?? null,
    enterpriseChargesConfig: result.enterprise_refund_charges_config ?? {},
    isExchangeForwardOrder: result.forward_exchange_order ?? false
  };
};
export const reasons = {
  isFetching: false,
  error: false,
  list: []
};

export const address = {
  name: '',
  address: '',
  landmark: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  phone: '',
  postal_code: '' // added for BFL
};

export const returnStatus = {
  items: [],
  metadata: {
    name: '',
    address: '',
    pincode: '',
    date: '',
    status: ''
  },
  timeline: {
    statuses: ['Request Placed', 'Confirmed', 'PickedUp', 'Reached Warehouse'],
    reached: 0,
    percentage: []
  },
  isFetching: false,
  error: false
};

export const returnTimeline = (statusCode, statusName, lang, subdomain) => {
  let query = {
    statuses: [
      strings[lang].requestPlaced,
      strings[lang].requestAccepted,
      subdomain === 'sprig-store'
        ? 'Shipment Pickedup'
        : strings[lang].pickedup,
      strings[lang].reachedWarehouse
    ],
    reached: 0,
    color: '#7ed9b2'
  };
  if (statusName === 'APPROVED') {
    query = {
      statuses: [
        strings[lang].requestPlaced,
        strings[lang].approved,
        strings[lang].requestAccepted,
        subdomain === 'sprig-store'
          ? 'Shipment Pickedup'
          : strings[lang].pickedup,
        strings[lang].reachedWarehouse
      ],
      reached: 1,
      color: '#7ed9b2'
    };
  } else if (statusName === 'REJECTED') {
    query = {
      statuses: [strings[lang].requestPlaced, strings[lang].requestCancelled],
      reached: 1,
      color: '#ff6500'
    };
  } else if (statusCode) {
    if (returnOrder.confirmed.indexOf(statusCode) > -1) {
      query.reached = 1;
      query.color = '#7ed9b2';
    } else if (returnOrder.cancelled.indexOf(statusCode) > -1) {
      query = {
        statuses: [strings[lang].requestPlaced, strings[lang].requestCancelled],
        reached: 1,
        color: '#ff6500'
      };
    } else if (returnOrder.pickedUp.indexOf(statusCode) > -1) {
      query.reached = 2;
      query.color = '#2b9366';
    } else if (returnOrder.delivered.indexOf(statusCode) > -1) {
      query.reached = 3;
      query.color = '#2b9366';
    }
  }
  query.percentage = calculatePercentage(query.statuses.length);
  return query;
};

const refundTimeline = (latestStatus, responseTimeline) => {
  const statusTimestamps = responseTimeline.reduce((acc, value) => {
    acc[value.status] = value.timestamp;
    return acc;
  }, {});
  if (!latestStatus) {
    return {
      timeline: []
    };
  }
  let timeline = [];
  if (['Initiated', 'Processed', 'Link Shared'].includes(latestStatus)) {
    timeline = [
      { status: 'Refund Initiated', timestamp: statusTimestamps.Initiated },
      { status: 'Refund Complete', timestamp: statusTimestamps.Processed }
    ];
  } else if (latestStatus === 'Failed') {
    timeline = [
      { status: 'Refund Initiated', timestamp: statusTimestamps.Initiated },
      { status: 'Refund Failed', timestamp: statusTimestamps.Failed }
    ];
  }
  const reached = ['Failed', 'Processed'].includes(latestStatus) ? 1 : 0;

  return {
    timeline,
    reached,
    color: latestStatus === 'Failed' ? '#f98178' : '#22c592'
  };
};

export const returnStatusMapping = (result, lang, subdomain) => ({
  items: result.item_info.map(i => ({
    description: i.item_info__description,
    quantity: i.return_quantity,
    price: i.item_info__price,
    sku: i.item_info__sku,
    image: i.item_info__images,
    url: i.item_info__product_url
  })),
  exchangeItem: result.exchange_item_info,
  metadata: {
    name: result.return_info.customer_returninfo_feed__return_name,
    address: result.return_info.customer_returninfo_feed__return_address,
    pincode: result.return_info.customer_returninfo_feed__return_pin_code,
    city: result.return_info.customer_returninfo_feed__return_city,
    state: result.return_info.customer_returninfo_feed__return_state,
    country: result.return_info.customer_returninfo_feed__return_country,
    date: result.return_info.customer_returninfo_feed__return_pickup_date,
    status: result.return_info.customer_returninfo_feed__status,
    isSelfShipped: result.return_info.customer_returninfo_feed__self_shipped,
    shippingLabel: result.return_info.shipping_label_url
  },
  timeline: returnTimeline(
    result.return_info.tracking_uber_status_code,
    result.return_info.customer_returninfo_feed__status,
    lang,
    subdomain
  ),
  pickupDate: result.return_info.customer_returninfo_feed__return_pickup_date,
  returnPickupDate:
    result.return_info.customer_returninfo_feed__return_tracking_info__cp_edd,
  requestApprovalDate:
    result.return_info.customer_returninfo_feed__request_approval_date,
  courierPartner:
    result.return_info
      .customer_returninfo_feed__return_tracking_info__courier_partner__name,
  remark:
    result.return_info
      .customer_returninfo_feed__return_tracking_info__latest_remark,
  awb: result.return_info.customer_returninfo_feed__return_tracking_info__awb,
  rejectionReason:
    result.return_info?.customer_returninfo_feed__reject_reason ?? '',
  refundAutomationEnabled: result?.refund_automation_enabled ?? false,
  refundTimeline: refundTimeline(
    result?.return_info?.refund_latest_status,
    result?.return_info?.refund_timeline
  )
});

export const issueType = [
  { id: 4, title: 'Delivery Agent did not call me' },
  { id: 1, title: 'No attempt was made to the delivery address' },
  { id: 2, title: 'I was not available when delivery attempt was made' },
  { id: 6, title: 'Do not want the product anymore' }
];

export const calendarDates = ({ itr, subdomain }) => {
  // skipFridays: Blocking all fridays for specific customer
  const skipFridays = ['fashionsa'];
  let iterations = itr || 4;
  let dateMeta = [];
  let date = new Date();
  for (var i = 1; i < iterations; i++) {
    date = new Date();
    date = date.setDate(date.getDate() + i);
    date = new Date(date);
    let dayNumber = new Date(date).getDay();
    let dayOfWeek = days[dayNumber];

    let year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      formattedDate = [
        year,
        month < 10 ? '0' + month : month,
        day < 10 ? '0' + day : day
      ].join('-');
    if (skipFridays.indexOf(subdomain) > -1 && dayNumber !== 5) {
      dateMeta.push({ date: formattedDate, dayOfWeek });
    } else if (skipFridays.indexOf(subdomain) === -1) {
      dateMeta.push({ date: formattedDate, dayOfWeek });
    } else {
      iterations = itr + 1 || 5;
    }
  }

  let currentDate = new Date(),
    someDate = new Date();
  let diff = Math.floor((date - currentDate) / (1000 * 60 * 60 * 24));
  someDate.setDate(someDate.getDate() + diff);
  return dateMeta;
};

export const evaluteTimeline = (order, trackingInfo, lang) => {
  const { code } = trackingInfo.latestStatus;
  const OrderPlacedTitle = ['rblbank', 'rblbank-test'].includes(
    trackingInfo?.config.user
  )
    ? 'Shipment Scheduled'
    : strings[lang].orderPlaced;
  order.timeline = [
    { status: 'orderPlaced', title: OrderPlacedTitle },
    { status: 'dispatched', title: strings[lang].dispatched },
    { status: 'inTransit', title: strings[lang].inTransit },
    { status: 'outForDelivery', title: strings[lang].ofd },
    { status: 'delivered', title: strings[lang].delivered }
  ];
  order.timelineColor = '#43c88e';
  if (order.orderPlaced.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = OrderPlacedTitle;
    trackingInfo.latestStatus.color = '#2196F3';
    order.reached = 0;
  } else if (order.readyToShip.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].readyToShip;
    trackingInfo.latestStatus.color = '#2196F3';
    order.reached = 0;
  } else if (order.dispatched.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].dispatched;
    trackingInfo.latestStatus.color = '#2196F3';
    order.reached = 1;
  } else if (order.inTransit.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].inTransit;
    trackingInfo.latestStatus.color = '#7ed9b2';
    order.reached = 2;
  } else if (order.outForDelivery.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].ofd;
    trackingInfo.latestStatus.color = '#43c88e';
    order.reached = 3;
  } else if (order.delivered.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].delivered;
    trackingInfo.latestStatus.color = '#2b9366';
    order.reached = 4;
  } else if (order.bad.indexOf(code) > -1) {
    if (order.lost.indexOf(code) > -1) {
      trackingInfo.latestStatus.text = strings[lang].lost;
      order.timeline = [
        { status: 'orderPlaced', title: OrderPlacedTitle },
        { status: 'dispatched', title: strings[lang].dispatched },
        { status: 'lost', title: strings[lang].lost }
      ];
    } else if (order.expired.indexOf(code) > -1) {
      trackingInfo.latestStatus.text = strings[lang].lost;
      order.timeline = [
        { status: 'orderPlaced', title: OrderPlacedTitle },
        { status: 'dispatched', title: strings[lang].dispatched },
        { status: 'expired', title: strings[lang].expired }
      ];
    } else if (order.damaged.indexOf(code) > -1) {
      trackingInfo.latestStatus.text = strings[lang].damaged;
      order.timeline = [
        { status: 'orderPlaced', title: OrderPlacedTitle },
        { status: 'dispatched', title: strings[lang].dispatched },
        { status: 'damaged', title: strings[lang].damaged },
        { status: 'outForDelivery', title: strings[lang].ofd },
        { status: 'delivered', title: strings[lang].delivered }
      ];
    }
    order.reached = 2;
    order.timelineColor = '#ff001b';
    trackingInfo.latestStatus.color = '#ff001b';
  } else if (order.failedDelivery.indexOf(code) > -1) {
    order.isFailed = true;
    order.timelineColor = '#ff6500';
    order.timeline = [
      { status: 'orderPlaced', title: OrderPlacedTitle },
      { status: 'dispatched', title: strings[lang].dispatched },
      { status: 'inTransit', title: strings[lang].inTransit },
      { status: 'outForDelivery', title: strings[lang].ofd },
      { status: 'failedDelivery', title: strings[lang].failedDelivery },
      { status: 'delivered', title: strings[lang].delivered }
    ];
    order.reached = 4;
    trackingInfo.latestStatus.text = strings[lang].failedDelivery;
    trackingInfo.latestStatus.color = '#ff6500';
  } else if (order.returned.indexOf(code) > -1) {
    order.isRTO = true;
    order.timelineColor = '#ff6500';
    order.timeline = [
      { status: 'orderPlaced', title: OrderPlacedTitle },
      { status: 'dispatched', title: strings[lang].dispatched },
      { status: 'inTransit', title: strings[lang].inTransit },
      { status: 'outForDelivery', title: strings[lang].ofd },
      { status: 'returned', title: strings[lang].returned }
    ];
    order.reached = 4;
    trackingInfo.latestStatus.text = strings[lang].returned;
    trackingInfo.latestStatus.color = '#ff6500';
  } else if (order.cancelled.indexOf(code) > -1) {
    order.timelineColor = '#ff6500';
    order.timeline = [
      { status: 'orderPlaced', title: OrderPlacedTitle },
      { status: 'cancelled', title: strings[lang].cancelled }
    ];
    order.reached = 1;
    trackingInfo.latestStatus.text = strings[lang].cancelled;
    trackingInfo.latestStatus.color = '#ff6500';
  }
  trackingInfo.order = (({
    isFailed,
    isRTO,
    label,
    reached,
    state,
    timeline,
    timelineColor
  }) => ({ isFailed, isRTO, label, reached, state, timeline, timelineColor }))(
    order
  );

  if (trackingInfo.config.shipDate != null && trackingInfo.order.reached < 2) {
    trackingInfo.order.timeline.splice(1, 0, {
      status: 'readyToShip',
      title: strings[lang].readyToShip
    });
    if (
      trackingInfo.config.user === 'nykaa' &&
      trackingInfo.latestStatus.code === 1001
    ) {
      trackingInfo.order.reached += 2;
      trackingInfo.latestStatus.text = strings[lang].dispatched;
      trackingInfo.latestStatus.color = '#2196F3';
    } else {
      trackingInfo.order.reached += 1;
    }
    if (
      trackingInfo.order.timeline[trackingInfo.order.reached].status ===
      'readyToShip'
    ) {
      trackingInfo.latestStatus.text = strings[lang].readyToShip;
      trackingInfo.latestStatus.color = '#2196F3';
    }
    trackingInfo.order.percentage = calculatePercentage(
      trackingInfo.order.timeline.length
    );
  }

  const tempStatuses = trackingInfo.order.timeline.map(item => item.status);
  trackingInfo.order.timeSeries = [];
  if (trackingInfo.config.orderDate != null) {
    const idx = tempStatuses.indexOf('orderPlaced');
    if (idx > -1) {
      trackingInfo.order.timeline[
        idx
      ].day = trackingInfo.config.orderDate.substr(8, 2);
      trackingInfo.order.timeline[idx].month = getMonthName(
        Number(trackingInfo.config.orderDate.substr(5, 2))
      );
    }
  }
  if (trackingInfo.config.shipDate != null) {
    const idx = tempStatuses.indexOf('readyToShip');
    if (idx > -1) {
      if (trackingInfo.config.orderDate) {
        trackingInfo.order.timeline[
          idx
        ].day = trackingInfo.config.orderDate.substr(8, 2);
        trackingInfo.order.timeline[idx].month = getMonthName(
          Number(trackingInfo.config.orderDate.substr(5, 2))
        );
      }
    }
  }
  // Creating a flag for failed Delivery form, if order is marked as failedDelivery before and current status is inTransit
  const everFailed = trackingInfo.scans.filter(
    item => order.failedDelivery.indexOf(item.code) > -1
  );

  // If shipment is ever failed and still in transit mode, then counting this shipment as exception.
  // start from here //
  if (
    trackingInfo.config.formStatusCodes.indexOf(
      trackingInfo.latestStatus.code
    ) > -1 &&
    everFailed.length > 0
  ) {
    trackingInfo.config.takeAction = true; // It'll start showing Take Action button in metadata component
  }
  let { scans } = trackingInfo;
  let fdDate = null;
  let ofdDate = null;
  scans = scans.sort((b, a) => {
    var diff = new Date(a.date) - new Date(b.date);
    return diff / (Math.abs(diff) || 1);
  });
  for (let index = 0; index < scans.length; index++) {
    if (order.failedDelivery.indexOf(scans[index].code) > -1 && !fdDate) {
      fdDate = new Date(scans[index].timestamp);
    }
    if (order.outForDelivery.indexOf(scans[index].code) > -1 && !ofdDate) {
      ofdDate = new Date(scans[index].timestamp);
    }
  }
  if (
    ofdDate > fdDate &&
    ofdDate !== null &&
    fdDate !== null &&
    order.failedDeliveryOverride.indexOf(trackingInfo.latestStatus.code) < 0
  ) {
    trackingInfo.config.takeAction = false;
    trackingInfo.order.timelineColor = '#43c88e';
    trackingInfo.order.timeline = [
      { status: 'orderPlaced', title: OrderPlacedTitle },
      { status: 'dispatched', title: strings[lang].dispatched },
      { status: 'inTransit', title: strings[lang].inTransit },
      { status: 'outForDelivery', title: strings[lang].ofd },
      { status: 'delivered', title: strings[lang].delivered }
    ];
    trackingInfo.order.reached = 3;
    trackingInfo.latestStatus.text = strings[lang].ofd;
    trackingInfo.latestStatus.color = '#43c88e';
  } else if (
    fdDate > ofdDate &&
    ofdDate !== null &&
    fdDate !== null &&
    order.failedDeliveryOverride.indexOf(trackingInfo.latestStatus.code) < 0
  ) {
    trackingInfo.order.isFailed = true; // It'll push current route to failed delivery route
    trackingInfo.order.timelineColor = '#ff6500';
    trackingInfo.order.timeline = [
      { status: 'orderPlaced', title: OrderPlacedTitle },
      { status: 'dispatched', title: strings[lang].dispatched },
      { status: 'inTransit', title: strings[lang].inTransit },
      { status: 'outForDelivery', title: strings[lang].ofd },
      { status: 'failedDelivery', title: strings[lang].failedDelivery },
      { status: 'delivered', title: strings[lang].delivered }
    ];
    trackingInfo.order.reached = 4;
    trackingInfo.latestStatus.text = strings[lang].failedDelivery;
    trackingInfo.latestStatus.color = '#ff6500';
  }
  // ends here //

  trackingInfo.order.percentage = calculatePercentage(
    trackingInfo.order.timeline.length
  );
  // Making isUpdateContact is to false if shipment is ever fall into failed delivery.
  const blockedStatuses = trackingInfo.scans.filter(
    item => order.blockedContactUpdate.indexOf(item.code) > -1
  );
  if (blockedStatuses.length > 0) {
    trackingInfo.config.isUpdateContact = false;
  }

  // shop101 custom tracking page config start here //
  for (let i = trackingInfo.scans.length - 1; i >= 0; i--) {
    for (let j = 0; j < trackingInfo.order.timeline.length; j++) {
      if (
        order[trackingInfo.order.timeline[j].status].indexOf(
          trackingInfo.scans[i].code
        ) > -1
      ) {
        trackingInfo.order.timeline[j].timestamp =
          trackingInfo.scans[i].timestamp;
        trackingInfo.order.timeline[j].location =
          trackingInfo.scans[i].location;
      }
    }
  }
  let timestamp = '',
    location = '';
  for (let i = 0; i < trackingInfo.order.timeline.length; i++) {
    if (trackingInfo.order.timeline[i].status === 'orderPlaced') {
      timestamp = trackingInfo.order.timeline[i].timestamp;
      location = trackingInfo.order.timeline[i].location;
      delete trackingInfo.order.timeline[i].timestamp;
      delete trackingInfo.order.timeline[i].location;
    }
    if (trackingInfo.order.timeline[i].status === 'readyToShip') {
      trackingInfo.order.timeline[i].timestamp = timestamp;
      trackingInfo.order.timeline[i].location = location;
    }
  }
  // shop101 custom tracking page config ends here //
  // pharmeasy custom tracking page config start here //
  if (
    trackingInfo.config.user === 'pharmeasy-new' ||
    trackingInfo.config.user === 'pharmeasy' ||
    trackingInfo.config.user === 'pharmeasy-otc' ||
    trackingInfo.config.user === 'pharmeasy-test' ||
    trackingInfo.config.user === 'candere' ||
    trackingInfo.config.user === 'candere-test' ||
    trackingInfo.config.user === '6thstreet'
  ) {
    for (let i = 0; i < trackingInfo.order.timeline.length; i++) {
      if (trackingInfo.order.timeline[i].status === 'orderPlaced') {
        trackingInfo.order.timeline[i].title = strings[lang].readyForDispatch;
        if (trackingInfo.config.user === '6thstreet') {
          trackingInfo.order.timeline[i].title = strings[lang].readyToShip;
        }
        if (
          trackingInfo.config.user === 'candere' ||
          trackingInfo.config.user === 'candere-test'
        ) {
          trackingInfo.order.timeline[i].title = OrderPlacedTitle;
        }
      }
      if (trackingInfo.order.timeline[i].status === 'dispatched') {
        if (
          trackingInfo.config.user === 'candere' ||
          trackingInfo.config.user === 'candere-test'
        ) {
          trackingInfo.order.timeline[i].title = strings[lang].shipped;
        } else {
          trackingInfo.order.timeline[i].title = strings[lang].pickedup;
        }
      }
    }
    if (order.orderPlaced.indexOf(trackingInfo.latestStatus.code) > -1) {
      trackingInfo.latestStatus.text = strings[lang].readyForDispatch;
      if (trackingInfo.config.user === '6thstreet') {
        trackingInfo.latestStatus.text = strings[lang].readyToShip;
      }
    }
    if (
      order.dispatched.indexOf(trackingInfo.latestStatus.code) > -1 &&
      (trackingInfo.config.user === 'candere' ||
        trackingInfo.config.user === 'candere-test')
    ) {
      trackingInfo.latestStatus.text = strings[lang].shipped;
    } else {
      trackingInfo.latestStatus.text = strings[lang].pickedup;
    }
  }
  // pharmeasy custom tracking page config ends here //
  if (
    trackingInfo.config.user === 'theluxurycloset' ||
    trackingInfo.config.user === 'theluxurycloset-test'
  ) {
    trackingInfo.order.timelineColor = '#157F00';
  }
  return trackingInfo;
};

export const reverseTimeline = (order, trackingInfo, lang) => {
  // Creating a flag for pickup failed form, if order is marked as pickup-failed before and current status is inTransit
  const everFailed = trackingInfo.scans.filter(
    item => reverseOrder.pickupFailed.indexOf(item.code) > -1
  );

  // If shipment is ever failed and still in transit mode, then counting this shipment as exception.
  // start from here //
  if (
    trackingInfo.config.isRVP &&
    trackingInfo.config.isFailedDelivery &&
    (reverseOrder.pickupFailed.indexOf(trackingInfo.latestStatus.code) > -1 ||
      (trackingInfo.config.formStatusCodes.indexOf(
        trackingInfo.latestStatus.code
      ) > -1 &&
        everFailed.length > 0))
  ) {
    trackingInfo.config.takeAction = true; // It'll start showing Take Action button in metadata component
  } else {
    trackingInfo.config.takeAction = false;
  }
  const { code } = trackingInfo.latestStatus;
  order.timeline = [
    { status: 'pickupRequested', title: strings[lang].pickupRequested },
    { status: 'outForPickup', title: strings[lang].ofp },
    { status: 'pickedup', title: strings[lang].pickedup },
    { status: 'returned', title: strings[lang].reverseReturned }
  ];
  order.timelineColor = '#43c88e';
  if (order.pickupRequested.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].pickupRequested;
    trackingInfo.latestStatus.color = '#2196F3';
    order.reached = 0;
  } else if (order.outForPickup.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].ofp;
    trackingInfo.latestStatus.color = '#2196F3';
    order.reached = 1;
  } else if (order.pickedup.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].pickedup;
    trackingInfo.latestStatus.color = '#2b9366';
    order.reached = 2;
  } else if (order.returned.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].returned;
    trackingInfo.latestStatus.color = '#2b9366';
    order.reached = 3;
  } else if (order.pickupCancelled.indexOf(code) > -1) {
    order.timelineColor = '#ff6500';
    order.timeline = [
      { status: 'pickupRequested', title: strings[lang].pickupRequested },
      { status: 'pickupCancelled', title: strings[lang].pickupCancelled }
    ];
    trackingInfo.latestStatus.text = strings[lang].pickupCancelled;
    trackingInfo.latestStatus.color = '#ff001b';
    order.reached = 1;
  }

  if (order.pickupFailed.indexOf(code) > -1) {
    trackingInfo.latestStatus.text = strings[lang].pickupFailed;
    trackingInfo.latestStatus.color = '#ff6500';
    order.timelineColor = '#ff6500';
    order.timeline = [
      { status: 'pickupRequested', title: strings[lang].pickupRequested },
      { status: 'outForPickup', title: strings[lang].ofp },
      { status: 'pickupFailed', title: strings[lang].pickupFailed },
      { status: 'pickedup', title: strings[lang].pickedup },
      { status: 'returned', title: strings[lang].returned }
    ];
    order.reached = 2;
  }

  if (trackingInfo.config.user === 'abfrl-vhi' && trackingInfo.config.isRVP) {
    order.timeline = order.timeline.map(item => {
      if (item.status === 'pickupRequested') {
        item.title = strings[lang].returnPlaced;
      }
      return item;
    });
  }

  trackingInfo.order = (({
    isFailed,
    isRTO,
    label,
    reached,
    state,
    timeline,
    timelineColor
  }) => ({ isFailed, isRTO, label, reached, state, timeline, timelineColor }))(
    order
  );

  trackingInfo.order.percentage = calculatePercentage(
    trackingInfo.order.timeline.length
  );
  return trackingInfo;
};

export const storeCreditMapping = data => {
  let obj = {
    totalCredit: 0,
    totalAmountPaid: 0,
    hasStoreCredit: false,
    totalRefundAmount: 0
  };
  for (const key in data) {
    if (data[key].store_credits_used) {
      obj.hasStoreCredit = true;
      obj.totalCredit += data[key].store_credits_used * data[key].quantity || 0;
    }
    if (data[key].final_amount_paid) {
      obj.totalAmountPaid +=
        data[key].final_amount_paid * data[key].quantity || 0;
    }
    obj.totalRefundAmount +=
      (data[key].price - data[key].store_credits_used) * data[key].quantity;
  }
  return obj;
};

export const myOrderMapping = (data, user) => {
  const { lang } = user;
  data.shipments = data.shipments.filter(
    item => item.latest_status.clickpost_status_code !== 23
  );
  for (let index = 0; index < data.shipments.length; index++) {
    data.shipments[index].buttonName = strings[lang].trackOrder;
    const code = data.shipments[index].latest_status.clickpost_status_code;
    if (data.shipments[index].config.is_rvp) {
      if (reverseOrder.pickupRequested.indexOf(code) > -1) {
        data.shipments[index].latest_status.text =
          strings[lang].pickupRequested;
        data.shipments[index].latest_status.color = '#2196F3';
      } else if (reverseOrder.outForPickup.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].ofp;
        data.shipments[index].latest_status.color = '#2196F3';
      } else if (reverseOrder.pickedup.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].pickedup;
        data.shipments[index].latest_status.color = '#2b9366';
      } else if (reverseOrder.returned.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].returned;
        data.shipments[index].latest_status.color = '#2b9366';
      } else if (reverseOrder.pickupCancelled.indexOf(code) > -1) {
        data.shipments[index].latest_status.text =
          strings[lang].pickupCancelled;
        data.shipments[index].latest_status.color = '#ff001b';
      } else if (reverseOrder.pickupFailed.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].pickupFailed;
        data.shipments[index].latest_status.color = '#ff6500';
      }
    } else {
      if (order.orderPlaced.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].orderPlaced;
        data.shipments[index].latest_status.color = '#2196F3';

        // Check if the user's enterprise is one of the specified values
        if (
          user.enterprise === 'sleepcompany' ||
          user.enterprise === 'sleepcompany-test' ||
          user.enterprise === 'clickpost-returns-8'
        ) {
          // If the shipment has a waybill, append "But Not Shipped" to the status text
          if (data.shipments[index].waybill) {
            data.shipments[
              index
            ].latest_status.text = `${strings[lang].orderPlaced} But Not Shipped`;
          } else {
            // If the shipment doesn't have a waybill, set the status text to "Under Processing"
            data.shipments[index].latest_status.text = `Under Processing`;
          }
        }
      } else if (order.readyToShip.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].readyToShip;
        data.shipments[index].latest_status.color = '#2196F3';
      } else if (order.dispatched.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].dispatched;
        data.shipments[index].latest_status.color = '#2196F3';
      } else if (order.inTransit.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].inTransit;
        data.shipments[index].latest_status.color = '#7ed9b2';
      } else if (order.outForDelivery.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].ofd;
        data.shipments[index].latest_status.color = '#43c88e';
      } else if (order.delivered.indexOf(code) > -1) {
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        data.shipments[index].buttonName =
          new Date(
            data.shipments[index].latest_status.timestamp.replace(' ', 'T')
          ) < threeDaysAgo
            ? strings[lang].viewDetail
            : strings[lang].deliveryFeedback;
        data.shipments[index].latest_status.text = strings[lang].delivered;
        data.shipments[index].latest_status.color = '#2b9366';
      } else if (order.bad.indexOf(code) > -1) {
        if (order.lost.indexOf(code) > -1) {
          data.shipments[index].latest_status.text = strings[lang].lost;
        } else if (order.damaged.indexOf(code) > -1) {
          data.shipments[index].latest_status.text = strings[lang].damaged;
        }
        data.shipments[index].latest_status.color = '#ff001b';
      } else if (order.failedDelivery.indexOf(code) > -1) {
        data.shipments[index].buttonName = strings[lang].raiseConcern;
        data.shipments[index].latest_status.text = strings[lang].failedDelivery;
        data.shipments[index].latest_status.color = '#ff6500';
      } else if (order.returned.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].returned;
        data.shipments[index].latest_status.color = '#ff6500';
      } else if (order.cancelled.indexOf(code) > -1) {
        data.shipments[index].latest_status.text = strings[lang].cancelled;
        data.shipments[index].latest_status.color = '#ff6500';
      }
      if (
        user.enterprise === 'candere' ||
        user.enterprise === 'candere-test' ||
        user.enterprise === '6thstreet'
      ) {
        if (order.orderPlaced.indexOf(code) > -1) {
          data.shipments[index].latest_status.text =
            strings[lang].readyForDispatch;
          if (user.enterprise === '6thstreet') {
            data.shipments[index].latest_status.text =
              strings[lang].readyToShip;
          }
        }
        if (
          order.dispatched.indexOf(code) > -1 &&
          (user.enterprise === 'candere' || user.enterprise === 'candere-test')
        ) {
          data.shipments[index].latest_status.text = strings[lang].shipped;
        } else {
          data.shipments[index].latest_status.text = strings[lang].pickedup;
        }
      }
    }
    if (
      data.shipments[index].additional &&
      data.shipments[index].additional.order_details
    ) {
      data.shipments[index].skus =
        data.shipments[index].additional.order_details;
      // delete data.shipments[index].additional;
    }
    if (data.shipments[index].skus.length > 0) {
      for (let i = 0; i < data.shipments[index].skus.length; i++) {
        if (typeof data.shipments[index].skus[i].images === 'string') {
          data.shipments[index].skus[i].images = data.shipments[index].skus[
            i
          ].images.split(',');
        } else {
          data.shipments[index].skus[i].images =
            data.shipments[index].skus[i].images || [];
        }
      }
      data.shipments[index].skus = data.shipments[index].skus.sort(
        (a, b) => b.images.length - a.images.length
      );
    }

    if (!data.shipments[index].waybill) {
      if (order.cancelled.indexOf(code) > -1) {
        data.shipments[index].buttonName = strings[lang].cancelled;
      } else {
        data.shipments[index].buttonName = strings[lang].shippingSoon;
      }
    } else {
      let minStartDate = null;
      let maxEndDate = null;
      /**
       * Iterate through the order details of the shipment to identify the maximum end date for displaying on the order card,
       * but only if the shipment has a waybill number. This implies that all SKUs in the shipments are shipped together,
       * and the filtered minimum and maximum dates will be displayed at the item/SKU level.
       */
      for (
        let idx = 0;
        idx < data.shipments[index].additional.order_details.length;
        idx++
      ) {
        if (
          maxEndDate !== null &&
          new Date(
            data.shipments[index].additional.order_details[idx].edd.max
          ) > new Date(maxEndDate)
        ) {
          minStartDate =
            data.shipments[index].additional.order_details[idx]?.edd?.min;
          maxEndDate =
            data.shipments[index].additional.order_details[idx]?.edd?.max;
        } else {
          minStartDate =
            data.shipments[index].additional.order_details[idx]?.edd?.min;
          maxEndDate =
            data.shipments[index].additional.order_details[idx]?.edd?.max;
        }
      }
      /**
       * Iterate through the order details of the shipment and set the filtered max and min date at the item/SKU level.
       */

      if (minStartDate && maxEndDate) {
        for (
          let idex = 0;
          idex < data.shipments[index].additional.order_details.length;
          idex++
        ) {
          /**
           * This code block determines whether to show the Estimated Delivery Date (EDD) for each order detail in a shipment.
           *
           * It checks if the `clickpost_status_code` of the `latest_status` of the shipment is in the `eddStatuses` array of the order.
           * If it is, the `min` and `max` properties of the `edd` object of the order detail are set to `minStartDate` and `maxEndDate` respectively.
           * If it's not, the `min` and `max` properties of the `edd` object of the order detail are set to an empty string.
           * @todo: Temprarory fix for EDD, need to remove this once EDD handling is done from backend.
           */
          const showEdd =
            order.eddStatuses.indexOf(
              data.shipments[index].latest_status.clickpost_status_code
            ) > -1;
          data.shipments[index].additional.order_details[idex].edd.min = showEdd
            ? minStartDate
            : '';
          data.shipments[index].additional.order_details[idex].edd.max = showEdd
            ? maxEndDate
            : '';
        }
      }
    }
  }
  let object = {
    orderDate: data.order_placed_date,
    currencyCode: data.currency_code,
    totalAmount: data.total_amount,
    payment: data.payment,
    shipments: data.shipments,
    customer: {
      name: data.customer_info ? data.customer_info : 'NA',
      address: data.customer_address
    },
    currencySymbol: ''
  };

  if (object.currencyCode === 'INR') {
    object.currencySymbol = '₹';
  } else if (object.currencyCode === 'USD') {
    object.currencySymbol = '$';
  } else if (object.currencyCode === 'SAR') {
    object.currencySymbol = 'SAR ';
  } else if (object.currencyCode === 'AED') {
    object.currencySymbol = 'AED ';
  } else if (object.currencyCode === 'EUR') {
    object.currencySymbol = '€ ';
  } else {
    object.currencySymbol = '';
  }
  return object;
};

export const currencyMapper = countryCode => {
  switch (countryCode) {
    case 'USA':
      return '$';
    case 'AE':
      return 'AED';
    case 'SA':
      return 'SAR';
    case 'IT':
      return '€';
    case 'IN':
    case 'INR':
    default:
      return '₹';
  }
};

export const countryTimeMapper = countryCode => {
  switch (countryCode) {
    case 'IN':
      return '+05:30';
    case 'AE':
      return '+04:00';
    case 'SA':
      return '+03:00';
    case 'IT':
      return '+01:00';
    default:
      return '';
  }
};
export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/heic',
  'image/heif'
];

var matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;

export const isUrl = string => matcher.test(string);

export const dontCheckServiceability = ['brandsforless-test', 'brandsforless'];

// this logic can be expanded for further countries as and when BFL rolls out to other countries
export const bfl = {
  domains: ['brandsforless-test', 'brandsforless'],
  showPincode: ['in', 'sg'],
  hasSelfShip: ['US'],
  cities: {
    AE: [
      { label: 'Abu Dhabi', value: 'Abu Dhabi' },
      { label: 'Ajman', value: 'Ajman' },
      { label: 'AL Ain', value: 'AL Ain' },
      { label: 'Al Fujayrah', value: 'Al Fujayrah' },
      { label: 'Dubai', value: 'Dubai' },
      { label: 'Ras al Khaymah', value: 'Ras al Khaymah' },
      { label: 'Sharjah', value: 'Sharjah' },
      { label: 'Umm al Qaywayn', value: 'Umm al Qaywayn' }
    ]
  },
  phoneCodes: {
    AE: { code: '+971', format: '5XXXXXXXX' },
    SA: { code: '+966', format: '5XXXXXXXX' },
    SG: { code: '', format: 'XXXXXXXXXX' },
  },
  showNormalPhoneNumber: ['sg']
};

export const toastStyle = {
  error: {
    style: {
      background: '#fff',
      color: '#D82F20',
      border: '1px solid #D82F20'
    },
    iconTheme: {
      primary: '#D82F20',
      secondary: '#FFD1D1'
    }
  }
};

export const abfrlCourierRating = {
  '0': '%7b"QID1":"0"%7d',
  '1': '%7b"QID1":"1"%7d',
  '2': '%7b"QID1":"2"%7d',
  '3': '%7b"QID1":"3"%7d',
  '4': '%7b"QID1":"4"%7d',
  '5': '%7b"QID1":"5"%7d',
  '6': '%7b"QID1":"6"%7d',
  '7': '%7b"QID1":"7"%7d',
  '8': '%7b"QID1":"8"%7d',
  '9': '%7b"QID1":"9"%7d',
  '10': '%7b"QID1":"10"%7d'
};

export const refundPriceBreakupMappings = (
  orderInfo,
  refundConfig,
  returnCharges,
  selectedMethod
) => {
  const priceBreakupParts = [
    { label: 'Item Amount', value: 'price' },
    {
      label: 'Tax',
      value: 'tax_amount',
      prefix: '+'
    },
    { label: 'Shipping Amount', value: 'shipping_amount', prefix: '+' },
    {
      label: 'Discount',
      value: 'discount',
      prefix: '-'
    }
  ];
  const { request, refundAutomationEnabled, products } = orderInfo;

  let options =
    orderInfo.orderType === 'PREPAID' ? 'prepaid_options' : 'cod_options';
  let adjustValue = refundConfig?.[options]?.find(
    item => item.refund_name === selectedMethod
  )?.adjustmentAmount;

  const priceBreakup = products.reduce((breakup, prod) => {
    if (!(prod.id in request)) {
      return breakup;
    }

    if (
      Math.abs(adjustValue) &&
      !priceBreakupParts.some(item => item.value === 'adjustment_amount')
    ) {
      priceBreakupParts.push({
        label: 'Adjustments (Charges/Bonuses)',
        value: 'adjustment_amount'
      });
    } else {
      priceBreakupParts.splice(4, 1);
    }

    if (refundAutomationEnabled) {
      priceBreakupParts.forEach(item => {
        let value =
          (breakup[item.value] ?? 0) +
          prod.price_breakup?.[item.value] * request[prod.id].quantity;

        if (Number.isNaN(value)) {
          value = 0;
        }

        breakup[item.value] = Number(value.toFixed(2));

        if (
          !refundConfig.forward_shipping_charge &&
          item.value === 'shipping_amount'
        ) {
          breakup.shipping_amount = 0;
        }

        breakup.total =
          (breakup.total ?? 0) +
          (item.prefix === '-' ? -breakup[item.value] : breakup[item.value]);
      });
    }

    return breakup;
  }, {});

  priceBreakup.adjustment_amount =
    adjustValue >= 0 ? `+${adjustValue}` : adjustValue;
  adjustValue = adjustValue ? Number(adjustValue.toFixed(2)) : 0;

  priceBreakup.total = (
    priceBreakup?.total -
    ((returnCharges ?? 0).toFixed(2) ?? 0) +
    adjustValue
  )?.toFixed(2);

  if (!refundConfig.forward_shipping_charge) {
    priceBreakup.shipping_amount = 0;
  }

  return { priceBreakup, priceBreakupParts };
};

export const langOption = {
  en: 'English',
  ar: 'Arabic / عربي',
  hi: 'Hindi / हिंदी',
  ta: 'Tamil / தமிழ்',
  id: 'Indonesian / bahasa Indonesia',
  vi: 'Vietnamese / Tiếng Việt',
  te: 'Telugu / తెలుగు',
  mr: 'Marathi / मराठी',
  kn: 'Kannada / ಕನ್ನಡ',
  gu: 'Gujarati / ગુજરાતી',
  bn: 'Bengali / বাংলা',
  fr: 'French / Français'
};

export const lang = {
  en: 'English',
  ar: 'عربي',
  hi: 'हिंदी',
  ta: 'தமிழ்',
  id: 'bahasa Indonesia',
  vi: 'Tiếng Việt',
  te: 'తెలుగు',
  mr: 'मराठी',
  kn: 'ಕನ್ನಡ',
  gu: 'ગુજરાતી',
  bn: 'বাংলা',
  fr: 'Français'
};
// For nykaa brands we have custom flows in NPR
export const nykaaCustomActions = {
  brands: [
    'nykaa',
    'nykaafashion',
    'nykaa-test',
    'nykaa-b2b',
    'nykaab2btest',
    'nykaakaybeauty',
    'nykaanyveda',
    'nykaainternational',
    'nykdbynykaa'
  ],
  doNotShowNPRFormBuckets: [3, 5, 8]
};

// All statuses after picked up + cancelled (10) can be considered for reverse NPS form
export const reverseNPSStatuses = {
  picked: [4, 5, 6, 7, 8, 9, 18, 19, 20, 1004, 1005, 1006],
  cancelled: [10],
  all: [4, 5, 6, 7, 8, 9, 18, 19, 20, 1004, 1005, 1006, 10]
};

export const noServiceabilityCheck = {
  countries: ['it', 'sa', 'ae', 'sg'],
  brands: ['kushals']
};
