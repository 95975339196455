// const STAGING_SERVER = 'https://internal.clickpost.in/';
const GET_SERVER = 'https://analytics.clickpost.in';
const POST_SERVER = 'https://analytics.clickpost.in';
const CLICKPOST_TRACKING = 'https://clickpost-tracking.clickpost.in';
const RUNNING_SERVER = '';
const DASHBOARD_SERVER = 'https://dashboard.clickpost.ai';

const API_V1 = '/api/v1';
const API_V3 = '/api/v3';

const APIs = {
  TRACKING: `${RUNNING_SERVER + API_V1}/track-order?`,
  EDD: `${RUNNING_SERVER + API_V1}/edd/?`,
  GOOGLE_SEARCH: `${RUNNING_SERVER + API_V1}/google/?`,
  FEED: {
    GET: `${RUNNING_SERVER + API_V1}/tracking-feed/?`,
    POST: `${RUNNING_SERVER + API_V1}/tracking-feed/?`
  },
  OPS_FEED: `${RUNNING_SERVER + API_V1}/tracking-ops-feed/?`,
  RATING: {
    GET: `${RUNNING_SERVER + API_V1}/courier-rating/?`,
    POST: `${RUNNING_SERVER + API_V1}/courier-rating/?`,
    SHORTLINK: `${RUNNING_SERVER + API_V1}/survey-shortlink/?`
  },
  BRAND_RATING: `${RUNNING_SERVER + API_V1}/brand-rating/?`,
  REVERSE_RATING: `${RUNNING_SERVER + API_V1}/reverse-rating/?`,
  NPR: {
    FEED: `${RUNNING_SERVER + API_V1}/npr/tracking-feed/?`,
    OPS_FEED: `${RUNNING_SERVER + API_V1}/npr/tracking-ops-feed/?`
  },
  // HOSTNAME: `${RUNNING_SERVER + API_V1}/debug-host/?`,
  HEADER: {
    THE_SOULED_STORE: 'https://api.thesouledstore.com/api/v1/tags-menu'
  },
  RETURN: {
    NS_ORDER: `${RUNNING_SERVER + API_V1}/return-info/?`,
    NS_REASONS: `${RUNNING_SERVER + API_V1}/return-reasons?`,
    REQUEST: `${POST_SERVER}/tracking-page/api/v1/feed_return_reasons/?`,
    NS_REQUEST: `${RUNNING_SERVER + API_V1}/feed-return-reasons`,
    NS_STATUS: `${RUNNING_SERVER + API_V1}/return-status/?`,
    NS_PINCODE_SERVICEABILITY: `${RUNNING_SERVER +
      API_V1}/pincode-serviceability/?`,
    PRESIGNED_URL: `${POST_SERVER +
      API_V3}/file_upload/generate_presigned_url/?`,
    IFSC: 'https://ifsc.razorpay.com/',
    SERVICEABLE_AREAS: `${RUNNING_SERVER + API_V1}/area-serviceability/?`
  },
  EXCHANGE: {
    VARIANTS: `${GET_SERVER}/tracking-page/api/v1/fetch_variants/?`,
    VARIANTS_CLIENT: `${RUNNING_SERVER + API_V1}/fetch-variants?`,
    CATALOGUE: {
      CATEGORIES: `${GET_SERVER}/api/exchange/v1/categories`,
      NS_CATEGORIES: `${RUNNING_SERVER + API_V1}/fetch-categories`,
      PRODUCTS: `${GET_SERVER}/api/exchange/v1/products`,
      NS_PRODUCTS: `${RUNNING_SERVER + API_V1}/fetch-products`,
      PRODUCT_INFO: `${GET_SERVER}/api/exchange/v1/product_variants`,
      NS_PRODUCT_INFO: `${RUNNING_SERVER + API_V1}/fetch-product-info`
    }
  },
  LOGIN: `${POST_SERVER + API_V3}/token-auth/`,
  THEME: `${GET_SERVER}/tracking-page/api/v1/page-style/?`, // Using in Nodejs
  UPDATE_THEME: `${RUNNING_SERVER + API_V1}/update-theme?subdomain=`,
  PINCODE: `${RUNNING_SERVER + API_V1}/pincode/?`,
  NS: {
    LOGIN: `${RUNNING_SERVER + API_V1}/login`
  },
  PAGE_REDIRECTION: `${RUNNING_SERVER + API_V1}/page-redirection`,
  PAYMENT_INFO: {
    NS: `${RUNNING_SERVER + API_V1}/payment-info?`,
    BACKEND: `${GET_SERVER}${API_V1}/cod-to-prepaid/order-detail/?`,
    OLD: `${CLICKPOST_TRACKING}${API_V1}/codToPrepaid/fetchShipmentCodToPrepaidRequest?`
  },
  PAYMENTS: {
    CREATE_ORDER_NS: `${RUNNING_SERVER + API_V1}/payments/create`,
    CREATE_ORDER: `${POST_SERVER}/tracking-page/api/v1/payment-gateway/order/`,
    VERIFY_ORDER_NS: `${RUNNING_SERVER + API_V1}/payments/verify`,
    VERIFY_ORDER: `${POST_SERVER}/tracking-page/api/v1/payment-gateway/update-order/`
  },
  ipify: 'https://api.ipify.org?format=json',
  uploadImage: `${RUNNING_SERVER + API_V1}/upload`,
  getIOSImage: `${RUNNING_SERVER + API_V1}/iosImage`,
  deleteImage: `${RUNNING_SERVER + API_V1}/deleteImage`
};

export { APIs, GET_SERVER, POST_SERVER, DASHBOARD_SERVER };
